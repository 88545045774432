import * as MixpanelGeneralActions from '@analytics/mixpanel/actions/General';
import { SeoLink } from '@common/SeoLink';
import Routes from '@constants/routes';
import { ABOUT_US, I18N_COMMON_CONTEXT, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '@features/footer/Footer.constants';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  BaseFooterText,
  Content,
  Email,
  Facebook,
  FooterText,
  Instagram,
  Logo,
  RightItems,
  SocialLinkContainer,
  SocialLinks,
  TermsAndPrivacy,
  TikTok,
  Twitter,
} from './Footer.styles';

const Footer = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation(I18N_COMMON_CONTEXT);

  const navigateTo = (route: string) => {
    router.push(route);
  };

  return (
    <Content>
      <Logo
        onClick={() => {
          MixpanelGeneralActions.onYelaLogoClicked({ router, position: 'Footer' });
          navigateTo(Routes.HOME);
        }}
      />
      <RightItems>
        <TermsAndPrivacy>
          <FooterText
            onClick={() => {
              MixpanelGeneralActions.onFooterLinkSelected({ router, link: 'About Us' });
              navigateTo(Routes.ABOUT);
            }}
          >
            {t(ABOUT_US)}
            <SeoLink href={Routes.ABOUT} />
          </FooterText>
          <FooterText
            onClick={() => {
              MixpanelGeneralActions.onFooterLinkSelected({ router, link: 'Terms and Conditions' });
              navigateTo(Routes.TERMS_AND_CONDITIONS);
            }}
          >
            {t(TERMS_AND_CONDITIONS)}
            <SeoLink href={Routes.TERMS_AND_CONDITIONS} />
          </FooterText>
          <BaseFooterText
            onClick={() => {
              MixpanelGeneralActions.onFooterLinkSelected({ router, link: 'Privacy Policy' });
              navigateTo(Routes.PRIVACY_POLICY);
            }}
          >
            {t(PRIVACY_POLICY)}
            <SeoLink href={Routes.PRIVACY_POLICY} />
          </BaseFooterText>
        </TermsAndPrivacy>
        <SocialLinks>
          <SocialLinkContainer
            onClick={() => {
              MixpanelGeneralActions.onFooterSocialButtonClicked({ router, button: 'Twitter' });
            }}
            href='https://twitter.com/WeAreYela'
            target='_blank'
            rel='​noopener noreferrer'
          >
            <Twitter />
          </SocialLinkContainer>
          <SocialLinkContainer
            onClick={() => {
              MixpanelGeneralActions.onFooterSocialButtonClicked({ router, button: 'Instagram' });
            }}
            href='https://www.instagram.com/weareyela/'
            target='_blank'
            rel='​noopener noreferrer'
          >
            <Instagram />
          </SocialLinkContainer>
          <SocialLinkContainer
            onClick={() => {
              MixpanelGeneralActions.onFooterSocialButtonClicked({ router, button: 'Email' });
            }}
            href='mailto:support@yela.com'
          >
            <Email />
          </SocialLinkContainer>
          <SocialLinkContainer
            onClick={() => {
              MixpanelGeneralActions.onFooterSocialButtonClicked({ router, button: 'Facebook' });
            }}
            href='https://www.facebook.com/weareyela'
            target='_blank'
            rel='​noopener noreferrer'
          >
            <Facebook />
          </SocialLinkContainer>
          <SocialLinkContainer
            onClick={() => {
              MixpanelGeneralActions.onFooterSocialButtonClicked({ router, button: 'TikTok' });
            }}
            href='https://www.tiktok.com/@weareyela'
            target='_blank'
            rel='​noopener noreferrer'
          >
            <TikTok />
          </SocialLinkContainer>
        </SocialLinks>
      </RightItems>
    </Content>
  );
};

export default Footer;
