import { SVGProps } from 'react';

export default function Instagram(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5831 0H5.41648C3.98057 0.00220401 2.6041 0.573595 1.58876 1.58894C0.573412 2.60428 0.00202091 3.98075 -0.000183105 5.41667L-0.000183105 14.5833C0.00202091 16.0192 0.573412 17.3957 1.58876 18.4111C2.6041 19.4264 3.98057 19.9978 5.41648 20H14.5831C16.0191 19.9978 17.3955 19.4264 18.4109 18.4111C19.4262 17.3957 19.9976 16.0192 19.9998 14.5833V5.41667C19.9976 3.98075 19.4262 2.60428 18.4109 1.58894C17.3955 0.573595 16.0191 0.00220401 14.5831 0V0ZM9.99982 14.5833C9.09332 14.5833 8.20718 14.3145 7.45345 13.8109C6.69973 13.3073 6.11227 12.5915 5.76537 11.754C5.41847 10.9165 5.3277 9.99492 5.50455 9.10584C5.6814 8.21676 6.11792 7.40008 6.75891 6.75909C7.3999 6.1181 8.21657 5.68158 9.10565 5.50473C9.99473 5.32789 10.9163 5.41865 11.7538 5.76555C12.5913 6.11245 13.3071 6.69991 13.8107 7.45364C14.3143 8.20736 14.5831 9.0935 14.5831 10C14.5831 11.2156 14.1003 12.3814 13.2407 13.2409C12.3812 14.1004 11.2154 14.5833 9.99982 14.5833ZM15.4165 5.41667C15.1693 5.41667 14.9276 5.34336 14.722 5.206C14.5165 5.06865 14.3562 4.87343 14.2616 4.64502C14.167 4.41661 14.1423 4.16528 14.1905 3.9228C14.2387 3.68033 14.3578 3.4576 14.5326 3.28278C14.7074 3.10797 14.9301 2.98892 15.1726 2.94069C15.4151 2.89245 15.6664 2.91721 15.8948 3.01182C16.1232 3.10643 16.3185 3.26664 16.4558 3.4722C16.5932 3.67777 16.6665 3.91944 16.6665 4.16667C16.6665 4.49819 16.5348 4.81613 16.3004 5.05055C16.0659 5.28497 15.748 5.41667 15.4165 5.41667Z'
        fill='currentColor'
      />
    </svg>
  );
}
