import EmailLogo from '@assets/email';
import TikTokLogo from '@assets/tiktok';
import FacebookLogo from '@assets/facebook';
import InstagramLogo from '@assets/instagram';
import YelaLogo from '@assets/logo';
import TwitterLogo from '@assets/twitter';
import tw, { css, styled } from 'twin.macro';

export const Content = styled.div`
  z-index: 1;
  height: 304px;
  ${tw`lg:h-13 xl:h-13`};
  ${tw`sm:my-6 md:my-6 lg:px-8 xl:px-8`}
  ${tw`lg:flex lg:justify-between lg:items-center`}
  ${tw`xl:flex xl:justify-between xl:items-center`}
`;

export const Logo = styled(YelaLogo)`
  ${tw`cursor-pointer`}
  ${tw`sm:m-auto`}
  ${tw`md:m-auto`}
`;

export const RightItems = styled.div`
  ${tw`lg:flex lg:flex-row lg:space-s-12 lg:items-center`}
  ${tw`xl:flex xl:flex-row xl:space-s-12 xl:items-center`}
`;

export const TermsAndPrivacy = styled.div`
  ${tw`text-light-high`}
  ${tw`sm:my-7`}
  ${tw`md:my-7`}
  ${tw`lg:flex lg:flex-row lg:space-s-2 lg:items-center lg:m-1`}
  ${tw`xl:flex xl:flex-row xl:space-s-2 xl:items-center xl:m-1`}
`;

export const BaseFooterText = styled.div`
  width: fit-content;
  ${tw`cursor-pointer`}
  ${tw`sm:m-auto`}
  ${tw`md:m-auto`}
`;

export const FooterText = styled(BaseFooterText)`
  ${tw`sm:mb-2`}
  ${tw`md:mb-2`}
`;

export const SocialLinks = styled.div`
  ${tw`flex flex-row items-center text-light-medium`}
  ${tw`sm:space-s-6 sm:justify-center`}
  ${tw`md:space-s-6 md:justify-center`}
  ${tw`lg:space-s-3`}
  ${tw`xl:space-s-3`}
`;

export const SocialLink = css`
  ${tw`cursor-pointer`}
`;

export const Twitter = styled(TwitterLogo)`
  ${SocialLink}
`;

export const Instagram = styled(InstagramLogo)`
  ${SocialLink}
`;

export const Email = styled(EmailLogo)`
  ${SocialLink}
`;

export const Facebook = styled(FacebookLogo)`
  ${SocialLink}
`;

export const TikTok = styled(TikTokLogo)`
  ${SocialLink}
`;

export const SocialLinkContainer = styled.a``;
