import { SVGProps } from 'react';

export default function TikTok(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.6799 1.92004H4.31992C2.99656 1.92004 1.91992 2.99668 1.91992 4.32004V19.68C1.91992 21.0034 2.99656 22.08 4.31992 22.08H19.6799C21.0033 22.08 22.0799 21.0034 22.0799 19.68V4.32004C22.0799 2.99668 21.0033 1.92004 19.6799 1.92004ZM17.7628 10.7151C17.6538 10.7252 17.5434 10.7319 17.4316 10.7319C16.1726 10.7319 15.0662 10.0844 14.4225 9.10564C14.4225 11.6732 14.4225 14.5944 14.4225 14.6434C14.4225 16.9037 12.5898 18.7364 10.3295 18.7364C8.0692 18.7364 6.23656 16.9037 6.23656 14.6434C6.23656 12.3831 8.0692 10.5504 10.3295 10.5504C10.415 10.5504 10.4985 10.5581 10.5825 10.5634V12.5804C10.4985 12.5703 10.4159 12.5549 10.3295 12.5549C9.1756 12.5549 8.24056 13.49 8.24056 14.6439C8.24056 15.7978 9.1756 16.7328 10.3295 16.7328C11.4834 16.7328 12.5025 15.8237 12.5025 14.6698C12.5025 14.6242 12.5226 5.26468 12.5226 5.26468H14.4503C14.6318 6.98836 16.0233 8.34868 17.7628 8.47348V10.7151Z'
        fill='currentColor'
      />
    </svg>
  );
}
