import { SVGProps } from 'react';

export default function Facebook(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.2915 12.1333C16.2792 12.235 16.2299 12.3285 16.1531 12.3961C16.0762 12.4638 15.9772 12.5007 15.8748 12.5H14.1665C14.056 12.5 13.95 12.5439 13.8719 12.622C13.7937 12.7002 13.7498 12.8062 13.7498 12.9167V19.5833C13.7498 19.6938 13.7937 19.7998 13.8719 19.878C13.95 19.9561 14.056 20 14.1665 20H19.1665C19.3875 20 19.5995 19.9122 19.7557 19.7559C19.912 19.5996 19.9998 19.3877 19.9998 19.1667V0.833333C19.9998 0.61232 19.912 0.400358 19.7557 0.244078C19.5995 0.0877974 19.3875 0 19.1665 0L0.83315 0C0.612136 0 0.400175 0.0877974 0.243895 0.244078C0.0876143 0.400358 -0.000183105 0.61232 -0.000183105 0.833333L-0.000183105 19.1667C-0.000183105 19.3877 0.0876143 19.5996 0.243895 19.7559C0.400175 19.9122 0.612136 20 0.83315 20H9.99982C10.1103 20 10.2163 19.9561 10.2944 19.878C10.3726 19.7998 10.4165 19.6938 10.4165 19.5833V12.9167C10.4165 12.8062 10.3726 12.7002 10.2944 12.622C10.2163 12.5439 10.1103 12.5 9.99982 12.5H8.33315C8.22264 12.5 8.11666 12.4561 8.03852 12.378C7.96038 12.2998 7.91648 12.1938 7.91648 12.0833V9.58333C7.91648 9.47283 7.96038 9.36685 8.03852 9.2887C8.11666 9.21056 8.22264 9.16667 8.33315 9.16667H9.99982C10.1103 9.16667 10.2163 9.12277 10.2944 9.04463C10.3726 8.96649 10.4165 8.86051 10.4165 8.75V7.65833C10.4165 6.40076 10.916 5.1947 11.8053 4.30547C12.6945 3.41623 13.9006 2.91667 15.1582 2.91667H16.2498C16.3603 2.91667 16.4663 2.96057 16.5444 3.03871C16.6226 3.11685 16.6665 3.22283 16.6665 3.33333V5.83333C16.6665 5.94384 16.6226 6.04982 16.5444 6.12796C16.4663 6.2061 16.3603 6.25 16.2498 6.25H15.1582C14.7846 6.25 14.4264 6.39838 14.1623 6.66249C13.8982 6.9266 13.7498 7.28482 13.7498 7.65833V8.75C13.7498 8.86051 13.7937 8.96649 13.8719 9.04463C13.95 9.12277 14.056 9.16667 14.1665 9.16667H16.1915C16.2507 9.16624 16.3093 9.17843 16.3634 9.20242C16.4175 9.22641 16.4659 9.26166 16.5053 9.30582C16.5447 9.34997 16.5743 9.40202 16.592 9.45849C16.6098 9.51496 16.6153 9.57457 16.6082 9.63333L16.2915 12.1333Z'
        fill='currentColor'
      />
    </svg>
  );
}
