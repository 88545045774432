import { SVGProps } from 'react';

export default function Email(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.8975 6.67914C22.8669 6.66613 22.833 6.66258 22.8004 6.66896C22.7677 6.67533 22.7377 6.69132 22.7142 6.71489L13.7794 15.6478C13.3067 16.1202 12.6658 16.3856 11.9974 16.3856C11.3291 16.3856 10.6881 16.1202 10.2154 15.6478L1.28435 6.71489C1.26098 6.69111 1.23096 6.67496 1.19823 6.66858C1.1655 6.6622 1.13161 6.66588 1.10102 6.67914C1.07096 6.69209 1.04537 6.71359 1.02744 6.74098C1.00951 6.76836 1.00003 6.80041 1.00018 6.83314V17.8331C1.00018 18.3194 1.19334 18.7857 1.53716 19.1295C1.88097 19.4733 2.34729 19.6665 2.83352 19.6665H21.1668C21.6531 19.6665 22.1194 19.4733 22.4632 19.1295C22.807 18.7857 23.0002 18.3194 23.0002 17.8331V6.83314C23.0003 6.80018 22.9906 6.76792 22.9723 6.7405C22.954 6.71307 22.928 6.69171 22.8975 6.67914Z'
        fill='currentColor'
      />
      <path
        d='M11.1887 14.6763C11.4039 14.8908 11.6953 15.0113 11.9991 15.0113C12.3029 15.0113 12.5943 14.8908 12.8094 14.6763L21.6882 5.7975C21.7483 5.73739 21.7905 5.66175 21.8101 5.57903C21.8297 5.4963 21.8259 5.40977 21.7991 5.32908C21.6983 5.0275 21.4178 5 21.1666 5H2.83331C2.58123 5 2.29889 5.0275 2.19989 5.32908C2.17312 5.40977 2.16932 5.4963 2.18891 5.57903C2.2085 5.66175 2.2507 5.73739 2.31081 5.7975L11.1887 14.6763Z'
        fill='currentColor'
      />
    </svg>
  );
}
